import { Heading } from "@chakra-ui/react"
import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"
import { colors } from "../../theme/theme"

const StyledHeading = styled(Heading)`
  text-align: center;
  color: ${colors.grey.dark};
`

interface ICustomH2Props {
  text: string
}

export const CustomH2: FunctionComponent<ICustomH2Props> = ({ text }) => {
  return <StyledHeading as="h2">{text}</StyledHeading>
}

export default CustomH2
